<template>
	<div class="goods-detail">
		<!-- <div class="goods-wrap">
			<ul class="list">
				<li v-for="(item, index) in goodslist" :key="index" @click="$router.push({ path: '/sku-' + item.sku_id, query: {site_id: item.site_id} })">
					<img :src="$img(item.goods_image, {size: 'mid'})"  @error="imageError(index)"  :data-obj="JSON.stringify(item)"/>
					<span>{{item.goods_name}}</span>
				</li>
			</ul>
		</div> -->
		

		<!-- 商品信息 -->
		<div class="detail-wrap">
			<div class="shop-left">
				<!-- <div class="shop-goods-cate" :class="{border:categoryList.length}">
					<div class="item" v-for="(item, index) in categoryList" :key="index">
						<div :class="filters.shop_category_id == item.category_id ? 'active' : 'submenu'" class="menu-name" @click="tabCate(item)">{{ item.category_name }}</div>
						<div class="g-list" v-show="item.gshow">
							<template v-for="(g,i) in item.goodslist">
								<div :key="i" class="li" @click="toDetail(g.sku_id)">
									{{ g.sku_name }}
								</div>
							</template>
						</div>
					</div>
				</div> -->
				<div class="shop-goods-cate" :class="{border:categoryList.length}">
					<div class="item" v-for="item in categoryList" :key="item.category_id">
					<div class="menu-name">
						<router-link :to="{ path: '/shop_list', query: { site_id: filters.site_id, shop_category_id: item.category_id, category_level: item.level } }">
							{{ item.category_name }}
						</router-link>
					</div>
					<div :class="filters.category_id == cate.category_id ? 'active' : 'submenu'"
						v-for="cate in item.child_list"
						:key="cate.category_id"
					>
							<router-link :to="{ path: '/shop_list', query: { site_id: filters.site_id, shop_category_id: cate.category_id, category_level: cate.level } }">
								{{ cate.category_name }}
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
				<el-tab-pane label="商品详情" name="detail">
					<div v-html="goodsSkuDetail.goods_content" v-loading="loading"></div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './detail';
	export default {
		name: 'detail',
		components: {
			PicZoom
		},
		mixins: [detail]
	};
</script>
<style lang="scss">
	.el-main {
        width: 100% !important;
    }
	@import './detail.scss';
</style>
