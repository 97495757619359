import {goodsSkuDetail,goodsSkuPage} from "@/api/goods/goods"
import {tree} from "@/api/shop/index"
// import {mapGetters} from "vuex"

export default {
	data: () => {
		return {
			skuId: 0,
			loading: true,

			picZoomUrl: "",
			thumbPosition: 0,
			// 是否可以移动
			moveThumbLeft: false,
			moveThumbRight: false,

			// 商品详情
			goodsSkuDetail: {
				video_url: ""
			},
			discountText: "距离结束仅剩",
			discountTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			qrcode: "",
			specDisabled: false,
			specBtnRepeat: false, //防止重复
			btnSwitch: false,

			// 店铺详情
			shopInfo: {},
			whetherCollection: 0,
			score: 0,

			categoryList: [],

			couponList: [], //优惠券列表
			couponBtnRepeat: false, //获取优惠券防止重复提交

			manjian: {}, //满减活动列表

			//评价
			currentPage: 1,
			pageSize: 20,
			total: 0,
			goodsEvaluateList: [],
			//组合套餐
			bundling: [{
				bundling_goods: {
					bl_name: "",
					sku_image: ""
				}
			}],
			service: null,

			number: 1,
			tabName: "detail",
			tabBundling: "",
			playerOptions: {
				playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
				autoplay: false, // 如果为true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: "zh-CN",
				aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{
					type: "video/mp4", // 类型
					src: "" // url地址
				}],
				poster: "", // 封面地址
				notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: true, // 当前时间和持续时间的分隔符
					durationDisplay: true, // 显示持续时间
					remainingTimeDisplay: true, // 是否显示剩余时间功能
					fullscreenToggle: true // 是否显示全屏按钮
				}
			},
			switchMedia: "img",

			// 是否关注店铺
			hasFollow: false,

			// 省市区县
			provinceArr: {},
			cityArr: {},
			districtArr: {},

			// 省市区县 id
			currTabAddres: "province",
			hideRegion: false,
			selectedAddress: {},

			filters: {
                site_id: 0,
                shop_category_id: 0,
                order: "",
                sort: "desc",
            },

			goodslist: []
		}
	},
	created() {
		this.skuId = this.$route.params.pathMatch
		this.getGoodsSkuDetail()
	},
	watch: {
		"filters.site_id": {
			handler(newValue, oldValue) {
				if (newValue != 0) {
					this.getCategoryList()
				}
			},
			immediate: true,
			deep: true
		},
		"filters.shop_category_id": {
			handler(newValue, oldValue) {
				if (newValue != oldValue) {
					this.getGoodsList()
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		tabChange(tab, event) {},
		bundlingChange(tab, event) {},
		toDetail(skuId){
			this.skuId = skuId
			this.getGoodsSkuDetail()
		},
		getGoodsSkuDetail() {
			goodsSkuDetail({
					sku_id: this.skuId
				})
				.then(res => {
					let data = res.data
					if (data.goods_sku_detail != null) {
						this.goodsSkuDetail = data.goods_sku_detail
						this.shopInfo = data.shop_info

						if (this.skuId == 0) this.skuId = this.goodsSkuDetail.sku_id

						this.filters.shop_category_id = this.goodsSkuDetail.goods_shop_category_ids
						this.filters.site_id = this.goodsSkuDetail.site_id
						
						this.loading = false
					} else {
						this.$router.push("/")
					}
				})
				.then(res => {
					
				})
				.catch(res => {
					this.loading = false
					this.$router.push("/")
				})
		},
		getGoodsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.siteId,
                ...this.filters
            }
            goodsSkuPage(params || {})
                .then(res => {
                    const { count, page_count, list } = res.data
                    this.total = count
					this.goodslist = list

					for(let i = 0;i < this.categoryList.length;i++) {
						if(this.filters.shop_category_id == this.categoryList[i].category_id){
							this.categoryList[i].goodslist = this.goodslist
							this.categoryList[i].gshow = 1
							this.$set(this.categoryList, i, this.categoryList[i])
						}
					}
                })
                .catch(err => {
                    
                })
        },
		getCategoryList() {
            tree({ site_id: this.filters.site_id })
                .then(res => {
					let catedata = res.data

                    this.categoryList = catedata
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
		tabCate(item) {
            this.filters.shop_category_id = item.category_id
            this.getGoodsList()

			for(let i = 0;i<this.categoryList.length;i++) {
				this.categoryList[i].gshow = 0
			}

			for(let i = 0;i<this.categoryList.length;i++) {
				if(this.filters.shop_category_id == this.categoryList[i].category_id){
					this.categoryList[i].gshow = 1
				}
			}
        },
		changeThumbImg(tag) {
			if (this.goodsSkuDetail.sku_images.length < 4) return
			let page = this.goodsSkuDetail.sku_images.length % 4 // 可见数量4个
			let position = 88
			if (page == 0) page = this.goodsSkuDetail.sku_images.length - 4 // 可见数量4个
			else if (page != 0 && page != 1 && page < 2) return

			if (tag == "prev") {
				if (this.thumbPosition != 0 && Math.round(this.thumbPosition, 2) != position) {
					this.thumbPosition += position
					// this.moveThumbLeft = true;
				} else {
					// this.moveThumbLeft = false;
				}
			} else if (tag == "next") {
				if (Math.round(this.thumbPosition, 2) != -Math.round(position * page, 2)) {
					this.thumbPosition -= position
					// this.moveThumbRight = true;
				} else {
					// this.moveThumbRight = false;
				}
			}
		},
		// 图片加载失败
		imageErrorEvaluate(index) {
			this.goodsEvaluateList[index].member_headimg = this.defaultHeadImage
		},
		handlePageSizeChange(size) {
			this.pageSize = size
		},
		handleCurrentPageChange(page) {
			this.currentPage = page
		},
		//输入数量
		keyInput() {
			var stock = this.goodsSkuDetail.stock
			// 库存为0
			if (this.goodsSkuDetail.stock == 0) {
				this.number = 0
				return
			}
			// 防止空
			if (this.number == 0 || this.number == '') this.number = 1

			var re = /^\d+$/
			if (re.test(parseInt(this.number))) {
				if (this.number > stock) {
					this.number = stock
				}
				this.number = parseInt(this.number)
			} else {
				this.number = 1
			}
		},
		// 播放回调
		onPlayerPlay(player) {},
		// 暂停回调
		onPlayerPause(player) {},
		// 视频播完回调
		onPlayerEnded(player) {},
		// DOM元素上的readyState更改导致播放停止
		onPlayerWaiting(player) {},
		// 已开始播放回调
		onPlayerPlaying(player) {},
		// 当播放器在当前播放位置下载数据时触发
		onPlayerLoadeddata(player) {},
		// 当前播放位置发生变化时触发。
		onPlayerTimeupdate(player) {},
		//媒体的readyState为HAVE_FUTURE_DATA或更高
		onPlayerCanplay(player) {},
		//媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
		onPlayerCanplaythrough(player) {},
		//播放状态改变回调
		playerStateChanged(playerCurrentState) {},
		//将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
		playerReadied(player) {},
		// 图片加载失败
		imageErrorSpec(index) {
			this.goodsSkuDetail.sku_images[index] = this.defaultGoodsImage
			this.picZoomUrl = this.defaultGoodsImage
		}
	}
}
